import React from "react";
import resumeFile from "../documents/Refail Gjeli.pdf";

const Resume = () => {
  const educationDetails = [
    {
      yearRange: "2012 - 2015",
      title: "Bachelor in Computer Science",
      place: "Ismail Qemaili University",
      desc: "Specialized in software development and computer systems, acquiring a solid foundation in algorithm design, data structures, and software engineering principles. ",
    },
    {
      yearRange: "2015 - 2017",
      title: "Master in Informatics",
      place: "Ismail Qemaili University",
      desc: "Enhanced understanding of operating systems, computer networks, and cyber security fundamentals. Participated in various team projects, demonstrating strong problem-solving skills and the ability to adapt to new technologies and methodologies.",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "2020 - current",
      title: "Sr. Full Stack Developer",
      place: "Fullscreen Digital",
      desc: "Specialize in full-stack development, leveraging a robust technical skillset in Node.js, React, MongoDB, and Express to create and maintain cutting-edge software solutions. ",
    },
    {
      yearRange: "2019 - 2020",
      title: "Software Developer",
      place: "Arkit",
      desc: "I demonstrated versatility and technical proficiency in software development with a focus on Node.js and PHP. Contributed to the development of a wide range of projects, including e-learning platforms and government-related applications.",
    },
    {
      yearRange: "2015 - 2018",
      title: "Full Stack Developer",
      place: "Self employed",
      desc: "As a self-employed web developer, I harnessed my comprehensive skill set in web technologies to design and develop a range of websites and web applications. A notable achievement was the creation of a sophisticated Content Management System (CMS) for a laboratory that specializes in analyses. ",
    },
  ];

  const skills = [{
    name: "JavaScript",
      percent: 90,
    },
    {
      name: "NodeJs",
      percent: 88,
    },
    {
      name: "React JS",
      percent: 85,
    },
    {
      name: "Web Design",
      percent: 95,
    },
    {
      name: "HTML",
      percent: 100,
    },
    {
      name: "Bootstrap",
      percent: 99,
    },
    {
      name: "CSS",
      percent: 60
    }
  ];

  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Resume</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          A summary of My Resume
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Education */}
          <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Education</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
          {/* My Experience */}
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* My Skills */}
        <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">My Skills</h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div className="progress progress-sm mb-4">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <p className="text-center mt-5 wow fadeInUp">
          <a
            className="btn btn-outline-dark shadow-none rounded-0"
            href={resumeFile}
            download
          >
            Download CV
          </a>
        </p>
      </div>
    </section>
  );
};

export default Resume;
