import React from "react";

const Services = () => {
  // services details
  const services = [
    {
      name: "Custom Software Development",
      desc: "Offering comprehensive custom software development services to streamline business operations and enhance productivity. With expertise in full-stack development, including Node.js, React, and MongoDB, I craft tailored solutions that precisely fit your business needs",
      icon: "fas fa-solid fa-code",
    },
    {
      name: "API Integration & Development",
      desc: "Specializing in API development and integration to facilitate seamless communication between different software systems. My services enable your business to leverage third-party functionalities, enhance existing applications, and improve data sharing capabilities. ",
      icon: "fas fa-solid fa-sitemap",
    },
    {
      name: "Cloud Solutions & Deployment",
      desc: "Providing cloud computing solutions that offer flexibility, scalability, and reliability. I assist businesses in migrating to the cloud, developing cloud-native applications, and leveraging the power of AWS and other cloud platforms to achieve optimal performance.",
      icon: "fas fa-solid fa-code-branch",
    },
  ];

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">What I Do?</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          How I can help your next project
        </h2>
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0">{service.desc} </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
